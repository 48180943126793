import React, { useState } from "react";
import { Icon, Label } from "semantic-ui-react";

import { calculateMenuProductPrice, ScaledButton, roundNumber, getPriceText } from "TempUtils";
import { FlipCard, FlipCardBack, FlipCardContent, FlipCardFront, FlipCardTrigger } from "Atoms";
import { useLanguage } from "Providers";
import { ProductDescription } from "./ProductDescription";
import "../expressCheckout.scss";
import { Box } from "Atoms";

export const Modifications = ({ modifications }) => {
    if (!modifications) return null;
    const shouldRenderFlavour = modifications.flavours && modifications.flavours.name !== null;
    const shouldRenderSize = modifications.sizes && modifications.sizes.name !== null;
    return (
        <React.Fragment>
            {shouldRenderSize && (
                <p className="EC-modification-text">
                    <strong>Storlek: </strong>
                    {modifications.sizes.name}
                </p>
            )}
            {shouldRenderFlavour && (
                <p className="EC-modification-text">
                    <strong>Smak: </strong>
                    {modifications.flavours.name}
                </p>
            )}
        </React.Fragment>
    );
};

const ProductUtils = ({ incrementProductAmount, decrementProductAmount, selectedProduct, upsellProductQuantity }) => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ScaledButton
            initalScale={1}
            pressedScale={0.7}
            onClick={() => decrementProductAmount(selectedProduct)}
            disabled={upsellProductQuantity === 0}
            className="EC-upsell-decrement-button"
        >
            <Icon name="minus" className="no-margin" />
        </ScaledButton>
        <span style={{ fontSize: "1.6em", margin: "0 0.5em" }} className="notranslate">
            {upsellProductQuantity} st
        </span>
        <ScaledButton
            initalScale={1}
            pressedScale={0.7}
            onClick={() => incrementProductAmount(selectedProduct)}
            className="EC-upsell-increment-button"
            circular
            icon="plus"
            size="huge"
        >
            <Icon name="plus" className="no-margin" />
        </ScaledButton>
    </div>
);

const renderCardContent = (selectedProd, product, priceText, productAmount, style, isCategoryToSelectFrom) => {
    const imageStyle = {
        filter:
            `${product.outOfStock ? "blur(2px) " : ""}` +
            `${product.limitReachedAndGrayImage ? " grayscale(100%)" : "grayscale(0%)"}` +
            `${!isCategoryToSelectFrom && productAmount === 0 ? "blur(2px) grayscale(100%)" : ""}`
    };
    return (
        <React.Fragment>
            <picture
                className="thumbnail"
                style={{ height: "250px", width: "250px", paddingTop: "5px", marginLeft: "auto", marginRight: "auto" }}
            >
                {productAmount > 0 && (
                    <Label circular size="huge" className="amount">
                        {productAmount} st
                    </Label>
                )}
                <img className="card-image" style={imageStyle} src={selectedProd.imageUrl} />
                {product.outOfStock && (
                    <Label style={{ position: "absolute" }} size="massive" color="red">
                        Tillfälligt slut
                    </Label>
                )}
            </picture>
            <div className="card-details" style={style}>
                <h2 className="card-title">{selectedProd.name}</h2>
                <p className="card-caption notranslate">{priceText}</p>
            </div>
        </React.Fragment>
    );
};

const ClickableCardContent = ({
    functionToUse,
    product,
    selectedProduct,
    priceText,
    productAmount,
    style,
    isPreselectedSingleItem,
    isCategoryToSelectFrom = true
}) => {
    const [isFlipped, setIsFlipped] = useState(false);

    return (
        <FlipCard isFlipped={isFlipped} onFlip={() => setIsFlipped(!isFlipped)} h="full">
            <FlipCardContent minW="250px" h="full">
                {selectedProduct.description && <FlipCardTrigger size="lg" />}
                <FlipCardFront>
                    <ScaledButton
                        className="scaled-button"
                        initalScale={1}
                        pressedScale={0.9}
                        disabled={product.outOfStock || isPreselectedSingleItem}
                        onClick={functionToUse}
                        style={{
                            backgroundColor: productAmount > 0 && "rgb(255, 255, 255)",
                            borderRadius: productAmount > 0 && "25px",
                            boxShadow: productAmount > 0 && "rgba(115, 189, 131, 0.72) 0px 0px 3px 5px",
                            border: productAmount > 0 && "rgba(109, 195, 131, 0.65)",
                            width: "100%"
                        }}
                    >
                        {renderCardContent(
                            selectedProduct,
                            product,
                            priceText,
                            productAmount,
                            style,
                            isCategoryToSelectFrom
                        )}
                    </ScaledButton>
                </FlipCardFront>
                <FlipCardBack h="full">
                    <ProductDescription product={selectedProduct} />
                </FlipCardBack>
            </FlipCardContent>
        </FlipCard>
    );
};

export const RefProductCard = ({
    product,
    productAmount,
    handleSelectProduct,
    className,
    style,
    priceText,
    isPreselectedSingleItem,
    isCategoryToSelectFrom,
    categoryIdx
}) => (
    <div className={className} style={style}>
        <ClickableCardContent
            isCategoryToSelectFrom={isCategoryToSelectFrom}
            categoryIdx={categoryIdx}
            isPreselectedSingleItem={isPreselectedSingleItem}
            product={product}
            selectedProduct={product}
            priceText={priceText}
            functionToUse={handleSelectProduct}
            productAmount={productAmount}
            style={style}
        />
    </div>
);

export const ProductCard = ({
    refProductHashMap,
    product,
    productCardStyle,
    shouldRenderProductUtils,
    productUtils,
    handleProductToCart,
    upsellProductQuantity
}) => {
    const { translate } = useLanguage();
    let selectedProduct;
    if (product.refProduct) {
        selectedProduct = product.refProduct;
        const modificationsToUse = product.modifications ? product.modifications : product.refProduct.modifications;
        if (modificationsToUse) {
            selectedProduct = { ...selectedProduct, modifications: modificationsToUse };
        }
    } else {
        selectedProduct = product.refBundleProduct;
    }

    const functionToUse = shouldRenderProductUtils ? productUtils.incrementProductAmount : handleProductToCart;
    const priceText = getPriceText(product, selectedProduct, refProductHashMap, translate);

    return (
        <div key={selectedProduct.name} className={productCardStyle}>
            <ClickableCardContent
                product={product}
                selectedProduct={selectedProduct}
                priceText={priceText}
                functionToUse={() => functionToUse(product)}
            />
            {shouldRenderProductUtils && upsellProductQuantity > 0 && (
                <ProductUtils
                    incrementProductAmount={productUtils.incrementProductAmount}
                    decrementProductAmount={productUtils.decrementProductAmount}
                    selectedProduct={product}
                    upsellProductQuantity={upsellProductQuantity}
                />
            )}
        </div>
    );
};

export const BundleProductCard = ({ product, handleProductToCart }) => (
    <article key={product.refBundleProduct.name} className="card" onClick={() => handleProductToCart(product)}>
        <picture className="thumbnail">
            <img className="card-image" src={product.refBundleProduct.imageUrl} />
        </picture>
        <div className="card-details">
            <h2 className="card-title">{product.refBundleProduct.name}</h2>
            <p className="card-caption">
                <Box as="span" className="notranslate">
                    {roundNumber(calculateMenuProductPrice(product))} kr
                </Box>
            </p>
        </div>
    </article>
);
