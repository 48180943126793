import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import { NewGrid as Grid, GridProps } from "Atoms";
import { useTheme } from "ThemeProvider";

const LandscapeCategoryProductGrid = styled.div`
    .grid-layout {
        grid-template-columns: auto repeat(3, 1fr) max-content;
        grid-template-rows: auto auto repeat(3, 1fr);
        grid-template-areas:
            "navigation advert advert advert advert"
            "navigation header header header cart"
            "navigation content content content cart"
            "navigation content content content cart"
            "navigation content content content cart";
        height: 100vh;
        width: 100vw;
        background: white;
    }
`;

const PortraitCategoryProductGrid = styled.div`
    .grid-layout {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, min-content) repeat(2, auto);
        grid-template-areas:
            "advert advert advert advert advert"
            "navigation navigation navigation navigation navigation"
            "header header header header header"
            "content content content content content"
            "cart cart cart cart cart";
        height: 100vh;
        width: 100vw;
        justify-content: center;
        margin: 0;
        padding: 0;
        background: white;
    }
`;

type Props = PropsWithChildren & GridProps;

export const ExpressCategoryProductLayout: React.FC<Props> = ({ children, ...props }) => {
    const {
        orientation: { isPortrait }
    } = useTheme();

    const Layout = isPortrait ? PortraitCategoryProductGrid : LandscapeCategoryProductGrid;

    return (
        <Layout>
            <Grid className="grid-layout" {...props}>
                {children}
            </Grid>
        </Layout>
    );
};
