import React, { PropsWithChildren } from "react";
import styled, { IStyledComponent } from "styled-components";

import { NewGrid as Grid, GridProps } from "Atoms";
import { useTheme } from "ThemeProvider";

export enum BUNDLE_CREATOR_CONTENT_VIEW {
    CREATOR = "CREATOR",
    SUMMARY = "SUMMARY",
    ADDONS = "ADDONS"
}

type StyledLayout = IStyledComponent<"web">;

const LandscapeCreatorGrid = styled.div`
    .grid-layout {
        grid-template-columns: 1fr 240px 3fr;
        grid-template-rows: auto auto repeat(2, 1fr) auto;
        grid-template-areas:
            "productInfo progress header header header"
            "productInfo progress content content content"
            "productInfo progress content content content"
            "productInfo progress content content content"
            "productInfo footerActions footerActions footerActions footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }

    .grid-layout-alternative {
        grid-template-columns: 1fr 4fr;
        grid-template-areas:
            "productInfo header header header header"
            "productInfo content content content content"
            "productInfo content content content content"
            "productInfo content content content content"
            "productInfo footerActions footerActions footerActions footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

const PortraitCreatorGrid = styled.div`
    .grid-layout {
        grid-template-columns: 4fr;
        grid-template-rows: repeat(3, max-content) repeat(2, auto) max-content;
        grid-template-areas:
            "productInfo"
            "progress"
            "header"
            "content"
            "content"
            "footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
    .grid-layout-alternative {
        grid-template-columns: 4fr;
        grid-template-rows: repeat(2, max-content) repeat(2, auto) max-content;
        grid-template-areas:
            "productInfo"
            "header"
            "content"
            "content"
            "footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

const LandscapeBundleAddonGrid = styled.div`
    .grid-layout {
        grid-template-columns: 1fr 3fr;
        grid-template-rows: repeat(4, 1fr) max-content;
        grid-template-areas:
            "productInfo addonsAndActions addonsAndActions addonsAndActions"
            "productInfo addonsAndActions addonsAndActions addonsAndActions"
            "productInfo addonsAndActions addonsAndActions addonsAndActions"
            "productInfo addonsAndActions addonsAndActions addonsAndActions"
            "productInfo footerActions footerActions footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

const PortraitBundleAddonGrid = styled.div`
    .grid-layout {
        grid-template-columns: 4fr;
        grid-template-rows: max-content repeat(4, auto) max-content;
        grid-template-areas:
            "productInfo"
            "addonsAndActions"
            "addonsAndActions"
            "addonsAndActions"
            "addonsAndActions"
            "footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

const LandscapeCreatorSummaryGrid = styled.div`
    .grid-layout {
        grid-template-columns: 1fr 3fr;
        grid-template-rows: repeat(3, 1fr) max-content max-content;
        grid-template-areas:
            "productInfo summary summary summary"
            "productInfo summary summary summary"
            "productInfo summary summary summary"
            "productInfo summary summary summary"
            "productInfo footerActions footerActions footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

const PortraitCreatorSummaryGrid = styled.div`
    .grid-layout {
        grid-template-columns: 4fr;
        grid-template-rows: max-content repeat(4, auto) max-content;
        grid-template-areas:
            "productInfo"
            "summary"
            "summary"
            "summary"
            "summart"
            "footerActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

type Props = {
    view: BUNDLE_CREATOR_CONTENT_VIEW;
    isAlternative?: boolean;
} & PropsWithChildren &
    GridProps;

export const ExpressBundleCreatorLayout: React.FC<Props> = ({ view, isAlternative = false, children, ...props }) => {
    const {
        orientation: { isPortrait }
    } = useTheme();

    const viewMap: Record<BUNDLE_CREATOR_CONTENT_VIEW, StyledLayout> = {
        [BUNDLE_CREATOR_CONTENT_VIEW.CREATOR]: isPortrait ? PortraitCreatorGrid : LandscapeCreatorGrid,
        [BUNDLE_CREATOR_CONTENT_VIEW.SUMMARY]: isPortrait ? PortraitCreatorSummaryGrid : LandscapeCreatorSummaryGrid,
        [BUNDLE_CREATOR_CONTENT_VIEW.ADDONS]: isPortrait ? PortraitBundleAddonGrid : LandscapeBundleAddonGrid
    };

    const Layout = viewMap[view];

    const isCreatorAlternative = view === BUNDLE_CREATOR_CONTENT_VIEW.CREATOR && isAlternative;

    return (
        <Layout>
            <Grid className={!isCreatorAlternative ? "grid-layout" : "grid-layout-alternative"} {...props}>
                {children}
            </Grid>
        </Layout>
    );
};
