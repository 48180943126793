import React, { PureComponent } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { graphql } from "react-apollo";
import { findLastIndex, flowRight as compose } from "lodash";

import { calculateMenuProductPrice } from "TempUtils";
import { modalNames } from "Constants";
import { ADD_MENU_PRODUCT_TO_EXPRESS_CART } from "../../../../../graphql/mutations/ExpressCartMutations";
import { ProductCards } from "../../utils/renderProductCards";
import "../../expressCheckout.scss";
import { getModificationLengths } from "TempUtils/TextFormat";
import { Box } from "Atoms";

class UpSellModal extends PureComponent {
    state = {
        selectedUpsellProducts: [],
        totalPrice: 0
    };

    addToCart = (selectedUpsellProducts, addToExpressCart, menuCategoryId) => {
        return selectedUpsellProducts.forEach(product => {
            addToExpressCart({
                variables: {
                    menuProduct: { ...product, menuCategoryId, upsell: true }
                }
            });
        });
    };

    handleCloseModal = (shouldRenderCheckout, selectedUpsellProducts, shouldGoToCheckout, menuCategoryId) => {
        const {
            modalContent: { selectedBundleProduct, handleProductToCart },
            addToExpressCart,
            closeAllModals
        } = this.props;
        // No need to perform mutation if there's no products
        if (selectedUpsellProducts.length > 0) {
            this.addToCart(selectedUpsellProducts, addToExpressCart, menuCategoryId);
        }

        if (selectedBundleProduct) {
            handleProductToCart(selectedBundleProduct);
        }

        if (shouldGoToCheckout) {
            closeAllModals();
            shouldRenderCheckout();
        }
        closeAllModals();
    };

    updateState = (product, callback = {}) => {
        this.setState(
            ({ selectedUpsellProducts, totalPrice }) => ({
                selectedUpsellProducts: selectedUpsellProducts.concat(product),
                totalPrice: totalPrice + calculateMenuProductPrice(product)
            }),
            () => callback
        );
    };

    addModification = (selectedFlavour, selectedSize, productContainer, menuProduct = null) => {
        const { closeModal } = this.props;
        const modifications = {
            flavours: selectedFlavour,
            sizes: selectedSize
        };

        // productContainer goes from ModModal.js and has a special type
        // menuProduct goes from incrementProductAmount function, only if product has only one modification
        const menuProductWithModifications = {
            refProduct: productContainer ? productContainer.product : menuProduct.refProduct,
            modifications
        };

        return this.updateState(menuProductWithModifications, closeModal(modalNames.MODMODAL));
    };

    incrementProductAmount = product => {
        if (product.refProduct && product.refProduct.modifications) {
            //prioritize menuProductModifications over refProduct modifications
            const modifications = product.modifications ? product.modifications : product.refProduct.modifications;
            const { sizes: numOfSizes, flavours: numOfFlavours } = getModificationLengths(modifications);
            const isOnlyOneModification = numOfSizes + numOfFlavours === 1;

            if (isOnlyOneModification) {
                if (numOfSizes > 0) {
                    this.addModification(null, modifications.sizes[0], null, product);
                } else if (numOfFlavours > 0) {
                    this.addModification(modifications.flavours[0], null, null, product);
                }
            } else {
                return this.props.openModal(modalNames.MODMODAL, {
                    ...product,
                    function: this.addModification
                });
            }
        } else {
            return this.updateState(product);
        }
    };

    decrementProductAmount = product => {
        const { selectedUpsellProducts } = this.state;

        const productToRemoveIndex = findLastIndex(
            selectedUpsellProducts,
            upsellProduct => upsellProduct.refProduct.id === product.refProduct.id
        );
        const productToRemove = selectedUpsellProducts[productToRemoveIndex];

        const updatedUpsellProducts = [
            ...selectedUpsellProducts.slice(0, productToRemoveIndex),
            ...selectedUpsellProducts.slice(productToRemoveIndex + 1)
        ];

        this.setState(prevState => ({
            selectedUpsellProducts: updatedUpsellProducts,
            totalPrice: prevState.totalPrice - calculateMenuProductPrice(productToRemove)
        }));
    };

    closeAndRenderCheckout = shouldGoToCheckout => {
        const {
            modalContent: { selectedBundleProduct, handleProductToCart },
            closeAllModals
        } = this.props;
        handleProductToCart(selectedBundleProduct);
        closeAllModals();
        if (shouldGoToCheckout) {
            shouldRenderCheckout();
        }
    };

    render() {
        const {
            modalContent: {
                handleProductToCart,
                shouldGoToCheckout,
                upsellCategory,
                shouldRenderCheckout,
                outOfStockProductIds,
                hideStockProductIds
            },
            style,
            className
        } = this.props;
        const { selectedUpsellProducts, totalPrice } = this.state;

        return (
            <React.Fragment>
                <Modal
                    basic
                    open={true}
                    closeOnDimmerClick={false}
                    size="fullscreen"
                    id="upSellModal"
                    style={style}
                    className={className}
                >
                    <Modal.Header
                        style={{
                            display: "flex",
                            justifyContent: "flex-start"
                        }}
                    >
                        <h1 style={{ margin: "auto" }}>Önskas något mer?</h1>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <ProductCards
                                productCategory={upsellCategory}
                                handleProductToCart={handleProductToCart}
                                productUtils={{
                                    incrementProductAmount: this.incrementProductAmount,
                                    decrementProductAmount: this.decrementProductAmount
                                }}
                                shouldRenderProductUtils={true}
                                clickableCard={true}
                                selectedUpsellProducts={selectedUpsellProducts}
                                cardsPerRow={5}
                                outOfStockProductIds={outOfStockProductIds}
                                hideStockProductIds={hideStockProductIds}
                            />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <span style={{ fontSize: "1.7em" }}>
                            Totalt:{" "}
                            <Box as="span" className="notranslate">
                                {totalPrice} kr
                            </Box>
                        </span>
                        <Button
                            size="huge"
                            style={{
                                color: "#fff",
                                backgroundColor: selectedUpsellProducts.length > 0 ? "#89cb9c" : "#F19143"
                            }}
                            primary
                            onClick={() =>
                                this.handleCloseModal(
                                    shouldRenderCheckout,
                                    selectedUpsellProducts,
                                    shouldGoToCheckout,
                                    upsellCategory.id
                                )
                            }
                        >
                            <span>{selectedUpsellProducts.length > 0 ? "Vidare" : "Nej, Tack"}</span>
                            <Icon name="chevron right" />
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

export default compose(graphql(ADD_MENU_PRODUCT_TO_EXPRESS_CART, { name: "addToExpressCart" }))(UpSellModal);
