import React from "react";
import {Modal, Button} from "semantic-ui-react";
import {BundleProductCategories} from "../../utils/BundleStepsRenderUtils";
import {buildBundleItems} from "../../utils/bundleUtils";

const UpdateModal = ({modalContent, closeModal}) => {
    let foundProduct;
    const { outOfStockProductIds, hideStockProductIds, addons } = modalContent;

    const {menuProductCategories} = modalContent.menu;
    const cat = menuProductCategories.find(
        cat => cat.id === modalContent.orderProduct.menuCategoryId
    ); // Category for the bundle product

    for (let i = 0; i < menuProductCategories.length; i++) {
        foundProduct = cat.menuBundleProducts.find(
            prod =>
                prod.refBundleProduct &&
                prod.refBundleProduct.id ===
                modalContent.orderProduct.refBundleProductId
        );
        if (foundProduct) break;
    }

    const {bundleProductCategories} = foundProduct.refBundleProduct;
    const {refProductId} = modalContent.bundleRefProduct;

    const filterBundleCategories = bundleProductCategories.filter(cat =>
        cat.refProductIdList.includes(refProductId)
    );

    // Ugly hack to send down extra parameters when updating a bundleItem
    const bundleToUpdate = {
        ...foundProduct,
        toUpdate: true,
        cartProductId: modalContent.orderProduct.cartProductId,
        oldBundleItem: modalContent.bundleRefProduct,
        bundleProductCategoryId:
        modalContent.bundleRefProduct.bundleProductCategoryId,
        refBundleProduct: {
            ...foundProduct.refBundleProduct,
            bundleProductCategories: filterBundleCategories
        }
    };

    const refProductHashMap = _.keyBy(modalContent.companyRefProds, "id");
    const bundleCategories = buildBundleItems(bundleToUpdate, refProductHashMap, addons);

    return (
        <Modal
            open={true}
            closeOnDimmerClick={false}
            basic
            id="updateModal"
        >
            <Modal.Header className="space-between">
                Uppdatera din {bundleToUpdate.refBundleProduct.name}
                <Button size="big" onClick={() => closeModal("updateModal")} negative>
                    Stäng
                </Button>
            </Modal.Header>
            <Modal.Content>
                <BundleProductCategories
                    selectedBundleProduct={bundleToUpdate}
                    refProductHashMap={refProductHashMap}
                    selectedBundleItems={bundleCategories}
                    handleSelectedBundleItem={modalContent.handleSelectedBundleItem}
                    renderLimits={false}
                    willGoToNextBundleCategory={true}
                    outOfStockProductIds={outOfStockProductIds}
                    hideStockProductIds={hideStockProductIds}
                />
            </Modal.Content>
        </Modal>
    );
};

export default UpdateModal;
