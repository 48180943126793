import React, { Component } from "react";
import { Modal, Header, Card, Button } from "semantic-ui-react";

import { Box } from "Atoms";

const initialState = {
    selectedFlavour: null,
    selectedSize: null,
    amountOfMods: {}
};
class ModModal extends Component {
    static defaultProps = {
        modalContent: {
            modifications: [],
            refProduct: {
                modifications: []
            }
        }
    };

    state = initialState;

    componentDidMount() {
        const { modalContent } = this.props;
        const amountOfMods = this.calcAmountOfMods(modalContent);
        this.setState({ amountOfMods });
    }

    calcAmountOfMods = modalContent => {
        const modifications = this.selectedModifications(modalContent);

        return Object.keys(modifications).reduce((acc, curr) => {
            acc[`${curr}IsReq`] = modifications[curr].length > 0;
            return acc;
        }, {});
    };

    handleSelectedMod = (whichMod, modValue, product, { limit, modificationCatName, isBundleProd }) => {
        this.setState(
            {
                [whichMod === "size" ? "selectedSizeBtn" : "selectedFlavourBtn"]: modValue,
                [whichMod === "size" ? "selectedSize" : "selectedFlavour"]: modValue
            },
            () => {
                const {
                    amountOfMods: { sizesIsReq, flavoursIsReq },
                    selectedFlavour,
                    selectedSize
                } = this.state;

                if (sizesIsReq && !flavoursIsReq && selectedSize) {
                    this.props.modalContent.function(null, selectedSize, {
                        product,
                        limit,
                        modificationCatName,
                        isBundleProd
                    });
                }

                if (flavoursIsReq && !sizesIsReq && selectedFlavour) {
                    this.props.modalContent.function(selectedFlavour, null, {
                        product,
                        limit,
                        modificationCatName,
                        isBundleProd
                    });
                }

                if (flavoursIsReq && sizesIsReq && selectedSize && selectedFlavour) {
                    this.props.modalContent.function(selectedFlavour, selectedSize, {
                        product,
                        limit,
                        modificationCatName,
                        isBundleProd
                    });
                }
            }
        );
    };

    pluck = key => object => object[key];
    selectAddonPrice = this.pluck("addonPrice");
    selectPrice = this.pluck("price");

    typeOfProduct = modalContent => {
        return modalContent.refProduct ? modalContent.refProduct : modalContent.refBundleProduct;
    };

    selectedModifications = modalContent => {
        if (this.typeOfProduct(modalContent).menuProductModifications) {
            return this.typeOfProduct(modalContent).menuProductModifications;
        }
        if (modalContent.modifications) {
            return modalContent.modifications;
        } else if (this.typeOfProduct(modalContent).modifications) {
            // check if bundle product has modifications
            let modifications = this.typeOfProduct(modalContent).modifications;
            const { selectedBundleProd } = modalContent;
            if (selectedBundleProd && selectedBundleProd.menuBundleModifications) {
                const overrideMods = selectedBundleProd.menuBundleModifications.find(
                    mod => mod.refProductId === modalContent.refProduct.id
                );
                modifications = overrideMods && overrideMods.modifications ? overrideMods.modifications : modifications;
            }
            return modifications;
        }
    };

    getProductPrice = (modification, isBundleProd) => {
        const selectedAddonPrice = this.selectAddonPrice(modification);
        const selectedPrice = this.selectPrice(modification);

        if (selectedPrice === 0 || isBundleProd) {
            return selectedAddonPrice === 0 ? { price: 0, symbol: "" } : { price: selectedAddonPrice, symbol: "+" };
        }

        return { price: selectedPrice, symbol: "" };
    };

    render() {
        const { modalContent, style, className, closeModal } = this.props;
        const product = this.typeOfProduct(modalContent);
        const isBundleProd = !!modalContent.selectedBundleProd;
        const selectedModifications = this.selectedModifications(modalContent);

        return (
            <Modal open={true} closeOnDimmerClick={false} basic size="small" style={style} className={className}>
                <Modal.Header className="space-between">
                    <Box>Anpassa {product && product.name}</Box>
                    <Button size="big" onClick={() => closeModal("modModal")} negative>
                        Stäng
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    {selectedModifications.flavours.length > 0 && (
                        <div style={{ paddingTop: "1.5em" }}>
                            <Header>Smaker</Header>
                            <Card.Group itemsPerRow={4}>
                                {selectedModifications.flavours.map(flavour => {
                                    const getPrice = this.getProductPrice(
                                        flavour,
                                        isBundleProd // modalContent.isBundleProd
                                    );
                                    const { selectedFlavourBtn } = this.state;
                                    const isSelected = selectedFlavourBtn && selectedFlavourBtn.name === flavour.name;
                                    const priceText = getPrice.price > 0 && `${getPrice.symbol} ${getPrice.price} kr`;
                                    return (
                                        <Card
                                            key={flavour.name}
                                            style={{
                                                backgroundColor: isSelected ? "#5995ED" : null,
                                                height: "80px"
                                            }}
                                            onClick={() =>
                                                this.handleSelectedMod(
                                                    "flavour",
                                                    {
                                                        ...flavour,
                                                        price: getPrice.price
                                                    },
                                                    product,
                                                    modalContent
                                                )
                                            }
                                        >
                                            <Card.Content>
                                                <Card.Header
                                                    style={{
                                                        color: isSelected ? "#FFF" : null
                                                    }}
                                                >
                                                    {flavour.name}
                                                </Card.Header>
                                                {priceText && (
                                                    <Card.Description
                                                        style={{
                                                            color: isSelected ? "#F6F6F6" : null
                                                        }}
                                                        className="notranslate"
                                                    >
                                                        {priceText}
                                                    </Card.Description>
                                                )}
                                            </Card.Content>
                                        </Card>
                                    );
                                })}
                            </Card.Group>
                        </div>
                    )}
                    {selectedModifications.sizes.length > 0 && (
                        <div style={{ paddingTop: "1.5em" }}>
                            <Header>Storlekar</Header>
                            <Card.Group itemsPerRow={4}>
                                {selectedModifications.sizes.map(size => {
                                    const getPrice = this.getProductPrice(size, isBundleProd);
                                    const { selectedSizeBtn } = this.state;
                                    const isSelected = selectedSizeBtn && selectedSizeBtn.name === size.name;
                                    const priceText = getPrice.price > 0 && `${getPrice.symbol} ${getPrice.price} kr`;
                                    return (
                                        <Card
                                            key={size.name}
                                            style={{
                                                backgroundColor: isSelected ? "#5995ED" : null,
                                                height: "80px"
                                            }}
                                            onClick={() =>
                                                this.handleSelectedMod(
                                                    "size",
                                                    {
                                                        ...size,
                                                        price: getPrice.price
                                                    },
                                                    product,
                                                    modalContent
                                                )
                                            }
                                        >
                                            <Card.Content>
                                                <Card.Header
                                                    style={{
                                                        color: isSelected ? "#FFF" : null
                                                    }}
                                                >
                                                    {size.name}
                                                </Card.Header>
                                                <Card.Description
                                                    style={{
                                                        color: isSelected ? "#F6F6F6" : null
                                                    }}
                                                    className="notranslate"
                                                >
                                                    {priceText}
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    );
                                })}
                            </Card.Group>
                        </div>
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}

export default ModModal;
