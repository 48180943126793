import React from "react";
import { Header, Text, Box } from "Atoms";
type Props = {
    product: {
        name: string;
        description: string;
        allergens: any[];
    };
};

export const ProductDescription: React.FC<Props> = ({ product }) => {
    const displayAllergens = product.allergens?.length;

    return (
        <Box height="100%" width="100%" overflowY="auto" p="6" backgroundColor="gray.100" rounded="lg">
            <Header as="h2" fontSize="large">
                {product.name}
            </Header>
            <Text fontSize="large">{product.description}</Text>
            {!!displayAllergens && (
                <Box>
                    <Header as="h2" fontSize="large">
                        Allergener
                    </Header>
                    <Text fontSize="large">
                        {product.allergens
                            .filter(allergen => !!allergen)
                            .map(allergen => allergen.name)
                            .join(", ")}
                    </Text>
                </Box>
            )}
        </Box>
    );
};
