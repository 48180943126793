import React, { useState } from "react";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";

import { Modal, ModalBody, ModalHeader, ModalCloseBtn } from "Molecules";
import { Box, Button, Text, Flex, Input, Header } from "Atoms";
import { modals, IModalContext, useLanguage } from "Providers";
import { getActiveLanguage } from "../../utils/languageUtils";

type ModalContentProps = {
    handleSelectedTableNumber: (tableName: string, isTakeAway: boolean) => void;
};

export const TableServiceModal: React.FC<IModalContext<ModalContentProps>> = ({ closeModal, modalContent }) => {
    const { handleSelectedTableNumber } = modalContent;
    const [tableName, setTableName] = useState<string>("");
    const { translate } = useLanguage();

    const activeLanguage = getActiveLanguage();

    return (
        <Modal size="xs" open overlayProps={{ zIndex: 1400 }}>
            <ModalHeader>
                <Header mb={0}>{translate("tableService", activeLanguage)}</Header>
                <ModalCloseBtn onClick={() => closeModal(modals.tableServiceModal)} />
            </ModalHeader>
            <ModalBody px={6} pb={6} pt={0} fontSize="1.3rem">
                <Flex>
                    <Flex
                        direction={"column"}
                        alignItems="center"
                        justifyContent={"center"}
                        pr={6}
                        flex={1}
                        borderRight="1px solid #CBD5E0"
                        position={"relative"}
                    >
                        <Text color="gray.500">{translate("enterTableNumber", activeLanguage)}</Text>
                        <Input
                            size="xl"
                            fullWidth
                            textAlign="center"
                            type="number"
                            min={1}
                            autoFocus
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.persist();
                                setTableName(e.target.value);
                            }}
                        />
                        <Button
                            themeColor="green"
                            isDisabled={!tableName || +tableName < 0}
                            size="2xl"
                            fullWidth
                            mt={8}
                            onClick={() => {
                                handleSelectedTableNumber(tableName, false);
                                closeModal(modals.tableServiceModal);
                            }}
                        >
                            {translate("next", activeLanguage)}
                        </Button>
                    </Flex>
                    <Flex
                        direction={"column"}
                        alignItems="center"
                        justifyContent={"flex-start"}
                        h="auto"
                        pl={6}
                        flex={1}
                    >
                        <Text color="gray.500" fontWeight={"600"}>
                            {translate("noVacantTables", activeLanguage)}
                        </Text>
                        <Text color="gray.500" textAlign="center" fontWeight={"500"}>
                            {translate("switchToTakeAway", activeLanguage)}
                        </Text>

                        <Button
                            themeColor="orange"
                            size="2xl"
                            fullWidth
                            mt={"2.85rem"}
                            onClick={() => {
                                handleSelectedTableNumber("", true);
                                closeModal(modals.tableServiceModal);
                            }}
                        >
                            <Box as={FaShoppingBag} color="white" mr={2} />
                            {translate("takeAway", activeLanguage)}
                        </Button>
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
