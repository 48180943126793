import { addModificationIfSingle } from "TempUtils";
import { getModificationLengths } from "TempUtils/TextFormat";
import { isEmpty } from "lodash";

export const findBundleItemById = (choosenBundleItems, id) => {
    return choosenBundleItems.findIndex(bi => bi.refProductId === id);
};

export const removeBundleItemsById = (choosenBundleItems, id) => {
    return choosenBundleItems.filter(bi => bi.refProductId !== id);
};

export const buildBundleItems = (product, refProductHashMap, addons = []) => {
    const { bundleProductCategories } = product.refBundleProduct;
    return bundleProductCategories.reduce((categories, category) => {
        let choosenBundleItems = [];

        // if one item in list, make it preselected
        if (category.refProductIdList.length === 1) {
            const preSelectedProduct = refProductHashMap[category.refProductIdList[0]];
            const overriddenMods = product.menuBundleModifications.find(
                mod => mod.refProductId === preSelectedProduct.id
            );
            const modificationsToUse = overriddenMods ? overriddenMods.modifications : preSelectedProduct.modifications;
            const { sizes, flavours } = getModificationLengths(modificationsToUse);

            // ensure that if single modifications then preselect
            if (!!preSelectedProduct && sizes <= 1 && flavours <= 1 && !(addons && addons[preSelectedProduct.id])) {
                let mods;
                let addonPrice = 0;
                if (sizes <= 1 && flavours <= 1) {
                    mods = {
                        sizes: sizes ? modificationsToUse.sizes[0] : null,
                        flavours: flavours ? modificationsToUse.flavours[0] : null
                    };
                    addonPrice =
                        (sizes ? modificationsToUse.sizes[0].addonPrice : 0) +
                        (flavours ? modificationsToUse.flavours[0].addonPrice : 0);
                }
                const chosenBundleItem = {
                    addons: [],
                    name: preSelectedProduct.name,
                    refProductId: preSelectedProduct.id,
                    bundleProductCategoryId: category.id,
                    kdsUnitDisplayName: category.kdsUnitDisplayName ? category.kdsUnitDisplayName : "",
                    refProductCategoryId: preSelectedProduct.refProductCategoryId,
                    modifications: mods,
                    price: addonPrice
                };
                choosenBundleItems = [chosenBundleItem];
            }
        }

        categories[category.name] = {
            limit: category.limit,
            name: category.name,
            choosenBundleItems
        };
        return categories;
    }, {});
};
