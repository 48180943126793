import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";

import { Box, Flex, NewIconButton } from "Atoms";
import { Drawer, DrawerContent, DrawerOverlay } from "Organisms";
import {
    ExpressAdvertisingHeader,
    ExpressProductAddons,
    ExpressProductInformationCard,
    ExpressQuantitySelector
} from "../components";
import { ExpressProductAddonsLayout } from "../layout";
import { IDrawerComponentContext } from "Providers";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { Addon, MenuProduct, OnlineProduct, AddonsHashMapValue } from "Types";
import { getTextColor, calculateTotalAddonsPrice, formatFinancialNumbers } from "Utils";
import { ExpressButton } from "../components/shared/ExpressButton";

export const NumberAnimation: React.FC<{ start: number; end: number }> = ({ start, end }) => {
    const { companyLocale } = useQoplaStore();

    const [number, setNumber] = useState(start);
    const duration = 500;

    useEffect(() => {
        const range = Math.abs(end - start);
        const step = range > 100 ? 5 : 1;
        let increment = end > start ? step : -step;
        if (end === start) {
            increment = 0;
        }
        const stepTime = duration / range;

        let currentNumber = start;

        const timer = setInterval(() => {
            currentNumber += increment;
            setNumber(currentNumber);
            if ((increment > 0 && currentNumber >= end) || (increment < 0 && currentNumber <= end)) {
                clearInterval(timer);
            }
        }, stepTime);

        return () => clearInterval(timer);
    }, [start, end]);

    return <Box>{formatFinancialNumbers(number, companyLocale)}</Box>;
};

type Props = {
    product: OnlineProduct | null;
    outOfStockAddonNames: string[];
    categoryName: string;
    productAddons: AddonsHashMapValue;
    selectedProductAddons: Addon[];
    isEditing?: boolean;
};

export const ExpressProductAddonsDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { product, outOfStockAddonNames, categoryName, productAddons, selectedProductAddons, isEditing = false },
    onCloseDrawer
}) => {
    const {
        colors: { expressPrimary, expressMediumGrey },
        orientation: { isPortrait }
    } = useTheme();
    const { translateWithArgument, translate } = useLanguage();
    const { selectedShop } = useQoplaStore();
    const { productAmount, setProductAmount, addOrderProductToCart } = useOrderWindowsStore();

    const [selectedAddons, setSelectedAddons] = useState<Addon[]>(selectedProductAddons);
    const [productPrice, setProductPrice] = useState<{ oldPrice: number; newPrice: number }>({
        oldPrice: 0,
        newPrice: 0
    });
    const [originalProductAmount, setOriginalProductAmout] = useState(productAmount);
    const [openDrawer, setOpenDrawer] = useState(true);

    useEffect(() => {
        setProductAmount(!!productAmount ? productAmount : "1");
    }, []);

    useEffect(() => {
        let _productPrice = product?.price ?? product?.refProduct?.defaultPrice ?? 0;

        if (selectedAddons) {
            const addonsPrice = calculateTotalAddonsPrice(selectedAddons);
            _productPrice += addonsPrice;
        }

        if (+productAmount > 1) {
            _productPrice = +productAmount * _productPrice;
        }

        setProductPrice({
            oldPrice: !productPrice.oldPrice ? _productPrice : productPrice.newPrice,
            newPrice: _productPrice
        });
    }, [selectedAddons, productAmount]);

    if (!product) {
        return null;
    }

    const advertImage =
        selectedShop?.settings.expressPOSSettings?.advertisingImageUrl ??
        "https://s3-eu-west-1.amazonaws.com/qopla-dev/chopchopBoland/shop/Chop-Chop-2019121757201_(1).jpeg";

    const hasAddons = !!productAddons.length;

    const addProduct = (shouldReturnOriginalProduct = false) => {
        if (isEditing && shouldReturnOriginalProduct) {
            setProductAmount(originalProductAmount);
        }
        addOrderProductToCart(
            product as MenuProduct,
            selectedShop?.id!,
            product!.id,
            {} as any,
            false,
            "",
            shouldReturnOriginalProduct ? selectedProductAddons : selectedAddons,
            false,
            undefined,
            false,
            ""
        );
        handleCloseDrawer();
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        setProductAmount("");
        onCloseDrawer();
    };

    let addButtonText = !!selectedAddons.length ? translate("add") : translate("addWithoutAddons");
    if (isEditing) {
        addButtonText = translate("update");
    }

    let backButtonText = isEditing ? translate("back") : translateWithArgument("backTo", categoryName);

    return (
        <Drawer open={openDrawer} onClose={handleCloseDrawer} from={"right"} size={"full"}>
            <DrawerOverlay />
            <DrawerContent h={"100%"} w={"100%"}>
                <Box h={"100vh"} w={"auto"}>
                    <ExpressProductAddonsLayout>
                        {isPortrait && advertImage && (
                            <ExpressAdvertisingHeader imageUrl={advertImage} cancelButton={false} />
                        )}
                        <Box>
                            <Flex
                                gridArea={"goBack"}
                                h={"auto"}
                                alignItems={"center"}
                                py={12}
                                px={isPortrait ? 16 : 12}
                                onClick={() => (isEditing ? addProduct(true) : handleCloseDrawer())}
                            >
                                <NewIconButton
                                    icon={FaArrowLeft}
                                    backgroundColor={expressPrimary}
                                    rounded={"full"}
                                    color={getTextColor(expressPrimary)}
                                    height="40px"
                                    width="40px"
                                />
                                <Box fontSize={"xl"} ml={6}>
                                    {backButtonText}
                                </Box>
                            </Flex>
                            <Box gridArea={""} px={isPortrait ? 16 : 12}>
                                <ExpressProductInformationCard
                                    product={product as OnlineProduct}
                                    hasAddons={hasAddons}
                                    h="auto"
                                />
                            </Box>
                        </Box>
                        <Box
                            gridArea="addonsAndActions"
                            pt={16}
                            px={isPortrait ? 16 : 20}
                            borderLeft={!isPortrait && hasAddons ? `1px solid` : "none"}
                            borderColor={expressMediumGrey}
                        >
                            {hasAddons && (
                                <ExpressProductAddons
                                    productName={product?.refProduct?.name ?? ""}
                                    addonGroups={productAddons}
                                    selectedAddons={selectedAddons}
                                    setSelectedAddons={setSelectedAddons}
                                    addonProps={{ pr: 4 }}
                                    scrollProps={{ maxHeight: "66vh", width: "fit-content", pr: 4 }}
                                />
                            )}
                            <Flex
                                gridArea={"actionButtons"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                w="100%"
                                height={"auto"}
                                py={8}
                            >
                                <ExpressQuantitySelector
                                    decrementOrderProduct={() => setProductAmount(`${+productAmount - 1}`)}
                                    incrementOrderProduct={() => setProductAmount(`${+productAmount + 1}`)}
                                    quantity={+productAmount}
                                    size={"lg"}
                                    mr={4}
                                    isDecrementZeroDisabled
                                />
                                <ExpressButton
                                    size={"lg"}
                                    themeColor={expressPrimary}
                                    onClick={() => addProduct(false)}
                                    minWidth={"495px"}
                                >
                                    <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
                                        <Box>{addButtonText}</Box>
                                        <NumberAnimation start={productPrice.oldPrice} end={productPrice.newPrice} />
                                    </Flex>
                                </ExpressButton>
                            </Flex>
                        </Box>
                    </ExpressProductAddonsLayout>
                </Box>
            </DrawerContent>
        </Drawer>
    );
};
