import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import { NewGrid as Grid, GridProps } from "Atoms";
import { useTheme } from "ThemeProvider";

const PortraitProductAddonsGrid = styled.div`
    .grid-layout {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "advert"
            "goBack"
            "productInfo"
            "addonsAndActions";
        height: 100%;
        width: 100%;
        justify-content: center;
        margin: 0;
        padding: 0;
        background: white;
    }
`;

const LandscapeProductAddonsGrid = styled.div`
    .grid-layout {
        display: grid;
        grid-template-columns: "480px 1fr";
        grid-template-rows: "136px max-content auto";
        grid-template-areas:
            "goBack addonsAndActions"
            "productInfo addonsAndActions"
            "productInfo addonsAndActions";
        height: 100vh;
        width: 100%;
        background: white;
    }
`;

type Props = PropsWithChildren & GridProps;

export const ExpressProductAddonsLayout: React.FC<Props> = ({ children, ...props }) => {
    const {
        orientation: { isPortrait }
    } = useTheme();

    const Layout = isPortrait ? PortraitProductAddonsGrid : LandscapeProductAddonsGrid;

    return (
        <Layout>
            <Grid className="grid-layout" {...props}>
                {children}
            </Grid>
        </Layout>
    );
};
